<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="scss">
* {
    box-sizing: border-box;
}
#app {
    width: 100%;
    min-height: 100vh;
    font-size: 16px;
    // background: #f8f8f8;
}
.d_flex {
    display: flex;
}
.a_c {
    align-items: center;
}
.j_b {
    justify-content: space-between;
}
.j_c {
    justify-content: center;
}
.flex {
    flex: 1;
}
.flex_warp {
    flex-wrap: wrap;
}
.mt20 {
    margin-top: 20px;
}
.p20 {
    padding: 20px;
}
.font-14{
    font-size: 14px;
}
.red {
    color: #f00;
}
.content {
    padding: 15px;
}
input {
    border: none;
    background: transparent;
}
input::-webkit-input-placeholder {
    color: #999999;
    font-size: 14px;
}
</style>
<style type="text/css" lang="scss">
@import url("./assets/icon/iconfont.css");
</style>
