import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import 'amfe-flexible/index.js'
import Vant,{Lazyload} from 'vant';
import 'vant/lib/index.css';
import AMap from 'vue-amap';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import './css/base.scss'
import VueQuillEditor from 'vue-quill-editor'
import * as Quill from 'quill' // 引入编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// import Vconsole from 'vconsole'
 
// let vConsole = new Vconsole()
 
// Vue.use(vConsole)

Vue.prototype.$video = Video

Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(AMap);

AMap.initAMapApiLoader({
	key: 'f9d30101443aec8f08667c7f87c251d3',
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
		'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'
	],
	v: '1.4.4'
});

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

(function(window, document) {
	function resize() {
		var ww = window.innerWidth;
		//设计稿以750为宽度，我们把页面宽度设计为 7.5rem
		//baseSize就是postcss.config.js 里面设置的 rootValue 值，这个是设计稿中1rem转换成px的大小
		const baseSize = 100;
		//实际设备页面宽度和设计稿的比值
		const scale = ww / 750;
		//计算实际的rem值并赋予给html的font-size
		document.documentElement.style.fontSize = (baseSize * scale) + 'px';
	}
	if (document.readyState !== 'loading') {
		resize();
	} else {
		document.addEventListener('DOMContentLoaded', resize);
	}
	window.addEventListener('resize', resize);
})(window, document);



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
