import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { Toast } from "vant";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        redirect: "/index",
        children: [
            {
                path: "/index",
                name: "云宣传",
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ "../views/Index/Index.vue"
                    ),
            },
            {
                path: "/news",
                name: "news",
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ "../views/news/news.vue"
                    ),
            },
            {
                path: "/rankingList",
                name: "rankingList",
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ "../views/rankingList/rankingList.vue"
                    ),
            },
            {
                path: "/mine",
                name: "个人中心",
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ "../views/Mine/Mine.vue"
                    ),
            },
        ],
    },
    {
        path: "/video",
        name: "video",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/video/video.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Login/Login.vue"),
    },
    {
        path: "/record",
        name: "record",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/Record/Record.vue"
            ),
    },
    {
        path: "/answerDetail",
        name: "answerDetail",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/answerDetail/answerDetail.vue"
            ),
    },
    {
        path: "/recordDetail",
        name: "recordDetail",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/recordDetail/recordDetail.vue"
            ),
    },
    {
        path: "/userInfo",
        name: "userInfo",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/userInfo/userInfo.vue"
            ),
    },
    {
        path: "/integralRecord",
        name: "integralRecord",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/integralRecord/integralRecord.vue"
            ),
    },
    {
        path: "/mistakesList",
        name: "mistakesList",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/mistakesList/mistakesList.vue"
            ),
    },
    {
        path: "/errorLibrary",
        name: "errorLibrary",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/errorLibrary/errorLibrary.vue"
            ),
    },
    {
        path: "/commitment",
        name: "commitment",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/commitment/commitment.vue"
            ),
    },
    {
        path: "/punch",
        name: "punch",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/punch/punch.vue"),
    },
    {
        path: "/punch-record",
        name: "punch-record",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/punch/punch-record.vue"
            ),
    },
    {
        path: "/newsDetails",
        name: "newsDetails",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/news/newsDetails.vue"
            ),
    },
    {
        path: "/register",
        name: "register",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/register/register.vue"
            ),
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    const user = localStorage.getItem("UTOKEN");
    if (!user && !["/login", "/register"].includes(to.path)) {
        if (["register"].includes(to.path)) {
            next();
        } else {
            Toast("请先登录");
            next({
                path: "/login",
                query: {
                    ...to.query,
                },
            });
        }
    } else {
        next();
    }
});
export default router;
