<template>
    <div class="home">
        <div class="container">
            <router-view />
        </div>
        <div class="tabbar-box">
            <van-tabbar
                z-index="999"
                route
                :fixed="true"
                v-model="active"
                @change="change"
                :active-color="base.colorMain"
                inactive-color="#999999"
            >
                <van-tabbar-item replace to="/index">
                    <span>首页</span>
                    <template #icon="props">
                        <img
                            :src="props.active ? icon1.active : icon1.inactive"
                        />
                    </template>
                </van-tabbar-item>
                <van-tabbar-item replace to="/news">
                    <span>街镇新闻</span>
                    <template #icon="props">
                        <img
                            :src="props.active ? icon2.active : icon2.inactive"
                        />
                    </template>
                </van-tabbar-item>
                <van-tabbar-item replace to="/rankingList">
                    <span>排行榜</span>
                    <template #icon="props">
                        <img
                            :src="props.active ? icon3.active : icon3.inactive"
                        />
                    </template>
                </van-tabbar-item>
                <van-tabbar-item replace to="/mine" icon="user-o">
                    <span>我的</span>
                    <template #icon="props">
                        <img
                            :src="props.active ? icon4.active : icon4.inactive"
                        />
                    </template>
                </van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
import base from "@/css/base.scss";

export default {
    name: "Home",
    components: {},
    data() {
        return {
            active: 0,
            icon1: {
                inactive: require("../assets/index.png"),
                active: require("../assets/i_c.png"),
            },
            icon2: {
                inactive: require("../assets/news.png"),
                active: require("../assets/news_select.png"),
            },
            icon3: {
                inactive: require("../assets/icon_2_1.png"),
                active: require("../assets/icon_2_2.png"),
            },
            icon4: {
                inactive: require("../assets/me.png"),
                active: require("../assets/m_c.png"),
            },
            base,
        };
    },
    mounted() {},
    methods: {
        change(e) {
            // console.log(e);
        },
    },
};
</script>
<style scoped lang="scss">
.home {
    width: 100%;
    height: 100vh;
    .container {
        width: 100%;
        height: calc(100vh - 50px);
    }
}
::v-deep .van-tabbar--fixed {
    box-shadow: 0px -4px 12px 0px rgba(209, 209, 209, 0.25);
}
</style>
